const headerModule = {
	namespaced: true,
	state: {
		headerHeight: null,
		categoriesArray: [],
		categoriesPromise: undefined,
		loggedIn: false,
		cartNum: 0,
		userStatePromise: undefined,
	},
	getters: {},
	mutations: {
		setHeaderHeight(state, headerHeight) {
			state.headerHeight = headerHeight;
		},
		setCategories(state, categoriesData) {
			state.categoriesArray = categoriesData;
		},
		setCategoriesPromise(state, categoriesPromise) {
			state.categoriesPromise = categoriesPromise;
		},
		setLoggedIn(state, loggedIn) {
			state.loggedIn = loggedIn;
		},
		setCartNum(state, cartNum) {
			state.cartNum = cartNum;
		},
		setUserStatePromise(state, userStatePromise) {
			state.userStatePromise = userStatePromise;
		},
	},
	actions: {
		getCategories({ state, commit }, axios) {
			if (state.categoriesArray.length) {
				return state.categoriesArray;
			}
			if (state.categoriesPromise && state.categoriesPromise instanceof Promise) {
				return state.categoriesPromise;
			}
			const promise = axios.get("/api/categories").then((res) => {
				commit("setCategories", res.data);
				return res;
			});
			commit("setCategoriesPromise", promise);
			return promise;
		},
		clearCategories({ state, commit }) {
			commit("setCategories", []);
			commit("setCategoriesPromise", undefined);
		},
		getUserState({ state, commit, dispatch, rootState }, { axios, cartId }) {
			if (!cartId) {
				return;
			}
			if (state.userStatePromise && state.userStatePromise instanceof Promise) {
				return state.userStatePromise;
			}
			const promise = axios.post("/api/shopping_cart/num", { cart_id: cartId }).then((res) => {
				commit("setLoggedIn", res.data.login);
				commit("setCartNum", res.data.num);
				return res;
			});
			commit("setUserStatePromise", promise);
			return promise;
		},
	},
	modules: {},
}

export default headerModule;